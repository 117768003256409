<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button> </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      disable-router
      :table-data="sourceObject.results"
      :source-object="sourceObject"
      :header="header"
    />
  </div>
</template>

<script>
import TableFilters from "@/components/tables/TableFilters";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import tableColsPassageLog from "@/helpers/tableCols/passageLog";
export default {
  name: "UserPassageLog",
  components: { TableLayout, TableFilters },
  data() {
    return {
      id: this.$route.params.id,
      queries: query.passLog
    };
  },
  computed: {
    header() {
      const tempTableColsPassageLog = tableColsPassageLog.filter(el =>
        [
          "moment",
          "time",
          "by_card",
          "controller_details",
          "controller",
          "access_group_title",
          "controllersType"
        ].includes(el.value)
      );
      tempTableColsPassageLog.$insert(3, {
        text: "Название объекта",
        renderFunction: value => value?.object_details?.name
      });

      return tempTableColsPassageLog;
    },
    sourceObject() {
      return this.$store.getters.getPassLog;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, user: this.urlQuery.id }
    });
    this.$store.commit("SET_VALUE_SORT", "moment");
    this.isDateLoaded = true;
    this.$store.dispatch("fetchUserPassLog").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchUserPassLog").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearControllersPage").then(() => next());
  }
};
</script>

<style scoped></style>
