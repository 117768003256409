var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_vm._t("header",function(){return [(_vm.deleteButtonDirectionStart)?_c('th',[_vm._v("Удаление")]):_vm._e(),(_vm.deleteAll)?_c('th',{staticClass:"ma-0 pb-0 pt-0",staticStyle:{"min-width":"10px"}},[_c('v-checkbox',{attrs:{"value":"all","hide-details":""},on:{"click":function($event){return _vm.$emit('all_ckeck_click', { value: _vm.checkedAll })}},model:{value:(_vm.checkedAll),callback:function ($$v) {_vm.checkedAll=$$v},expression:"checkedAll"}})],1):_vm._e(),(_vm.restoreButton)?_c('th',[_vm._v("Восстановление")]):_vm._e(),_vm._l((_vm.header),function(head,index){return _c('th',{key:index,class:head.sort ? 'header-table' : '',style:(head.width ? 'min-width: ' + head.width + 'px' : ''),on:{"click":function($event){head.sort ? _vm.$emit('sort_click', { value: head.value }) : null}}},[_c('div',{staticClass:"d-flex align-center justify-center",class:head.value == _vm.currentArrowValue && head.sort
                ? 'header-table--active'
                : ''},[(head.src)?_c('img',{attrs:{"height":"16","src":head.src,"alt":head.value}}):_vm._t('header.' + head.value,function(){return [_vm._v(" "+_vm._s(head.text)+" ")]},{"head":head}),(head.sort)?_c('span',{staticClass:"ml-1 header-table__arrow",class:[
                head.value == _vm.currentArrowValue && _vm.arrowDirectionDown
                  ? 'header-table__arrow--down'
                  : '',
                head.value == _vm.currentArrowValue
                  ? ''
                  : 'header-table__arrow--none'
              ],staticStyle:{"width":"10px"}},[_c('img',{attrs:{"src":"/img/icons/up-arrow.svg","alt":"up-arrow"}})]):_vm._e()],2)])})]})],2)]),_c('tbody',[_vm._l((_vm.tableData),function(item,ind){return _c('tr',{key:ind,staticClass:"item",class:{
        '--hover-none': _vm.disableRouter,
        'blocked-user': item.is_active == false
      },on:{"click":function($event){return _vm.rowClick($event, item)}}},[_vm._t("delete-td-start",null,{"itemChild":item}),_vm._t("delete-all",null,{"itemChild":item}),_vm._t("restore-td-child",null,{"item":item}),_vm._l((_vm.header),function(head,index){return _c('td',{key:index,staticClass:"ym-hide-content"},[_vm._t('item.' + head.value,function(){return [(head.renderFunction)?_c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"background-color":"inherit"},domProps:{"innerHTML":_vm._s(head.renderFunction(item) || '-')}}):(head.text === '№')?_c('div',[_vm._v(_vm._s(ind + 1))]):_c('div',[_vm._v(_vm._s(item[head.value] || "-"))])]},{"item":item,"head":head})],2)}),_vm._t("delete-td",null,{"itemChild":item})],2)}),(_vm.tableData.length === 0)?_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"text-align":"center","width":"100%"}},[_vm._v(" Нет данных ")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }