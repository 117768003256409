<template>
  <div>
    <DatePicker
      v-if="object.inputType === 2"
      v-model="select"
      :label="object.name"
      rules="required"
      @input="setPreQuery"
      class="ym-disable-keys"
      :disabled="disabled"
    />

    <DatePicker
      v-else-if="object.inputType === 3"
      v-model="dates"
      range
      :label="object.name"
      @input="setPreQuery"
      class="ym-disable-keys"
      :disabled="disabled"
    />

    <v-combobox
      v-else-if="object.inputType === 1 && object.type === 'search'"
      :label="object.name"
      :loading="isLoading"
      :search-input.sync="searchValue"
      no-filter
      :value="select"
      @input="debounceInputOnSelect"
      @focus="asyncSearch('')"
      :hide-selected="false"
      :hide-no-data="!searchValue"
      no-data-text="Нет результатов"
      clearable
      @click:clear="clearItems"
      :items="items"
      class="ym-disable-keys"
      :class="{ 'loading-block': disabled }"
    >
    </v-combobox>

    <v-autocomplete
      v-else
      :label="object.name"
      :loading="isLoading"
      :search-input.sync="searchValue"
      v-model="select"
      @input="setPreQuery"
      @focus="asyncSearch('')"
      :hide-selected="false"
      no-data-text="Нет результатов"
      clearable
      @click:clear="clearItems"
      :items="items"
      class="ym-disable-keys"
      :disabled="disabled"
      :class="{ 'loading-block': disabled }"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/fields/DatePicker";
import moment from "moment";
import { debounce } from "lodash";

export default {
  name: "SearchSelectField",
  components: {
    DatePicker
  },
  props: {
    error: {
      type: String
    },
    object: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: "",
      isLoading: false,
      items: [],
      select: undefined,
      dates: [],
      isFirstLoad: true
    };
  },
  computed: {
    queries() {
      return this.$store.getters.getPageQuery;
    },
    preQuery() {
      return this.$store.getters.getPreQuery;
    }
  },
  mounted() {
    let value = this.queries.query[this.object.type];

    if (this.object.inputType === 1) {
      if (this.object.type === "search") {
        this.select = value;
      } else {
        this.select = !isNaN(value) ? Number(value) : value;
      }
    } else {
      const source = this.queries.query[this.object.type];
      value = source ? moment.unix(source).format("YYYY-MM-DD") : null;
      this.select = value;
    }

    const savedId = this.queries.query["ids"];
    if (savedId) {
      this.fetchOrgNameByIds(savedId);
    }
  },

  watch: {
    searchValue: {
      handler: function(val) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          const selectedItem = this.items.find(item => item.text === val);
          if (!this.select && this.object.type === "parents") {
            this.$store.commit("setPreQuery", {
              key: this.object.type,
              value: val || undefined
            });
          } else if (!val && !this.select && !this.isFirstLoad) {
            this.$store.commit("setPreQuery", {
              key: this.object.type,
              value: undefined
            });
            this.$store.dispatch("findResult");
          } else if (val && this.select) {
            this.asyncSearch(val || "");
            this.$store.dispatch("findResult");
          } else if ((this.select === undefined || !val) && !this.isFirstLoad) {
            if (this.object.type === "search") {
              this.$store.commit("setPreQuery", {
                key: this.object.type,
                value: val || undefined
              });
            } else {
              this.$store.commit("setPreQuery", {
                key: this.object.type,
                value: selectedItem ? selectedItem.text : undefined
              });
            }

            this.asyncSearch(val || "");
            this.$store.dispatch("findResult");
          } else if (!val && this.select) {
            this.asyncSearch(val || "");
          }

          this.isFirstLoad = false;
        }, 1000);
      },
      deep: true
    }
  },

  methods: {
    async fetchOrgNameByIds(id) {
      this.isLoading = true;
      try {
        const organization = await this.object.function("", { ids: id });
        if (organization && organization.length > 0) {
          this.select = organization[0].text;
        }
      } catch (error) {
        this.$store.commit("SET_ERROR_NOTIFICATION", {
          errors: [
            {
              field_verbose: "Ошибка",
              message: "Некорректное значение"
            }
          ]
        });
      } finally {
        this.isLoading = false;
      }
    },
    clearItems() {
      // Implement the logic to clear the items
    },
    setPreQuery() {
      if (this.object.inputType === 1) {
        if (this.object.type === "search") {
          const currentPath = this.$route.fullPath;
          const newPath = this.$route.path + "/" + this.select;
          if (currentPath !== newPath) {
            this.$router.push({ path: newPath });
          }
        } else {
          this.$store.commit("setPreQuery", {
            key: this.object.type,
            value: this.select
          });
        }
      } else if (this.object.inputType === 3) {
        const dates = this.sorting(this.dates);
        this.$store.commit("setPreQuery", {
          key: this.object.startValue,
          value: Array.isArray(dates)
            ? moment(dates[0])
                .add(moment().utcOffset(), "minutes")
                .unix()
            : null
        });
        this.$store.commit("setPreQuery", {
          key: this.object.endValue,
          value: Array.isArray(dates)
            ? moment(dates[1])
                .add(moment().utcOffset(), "minutes")
                .unix()
            : null
        });
      } else if (this.object.inputType === 2) {
        this.$store.commit("setPreQuery", {
          key: this.object.type,
          value: this.select
            ? moment(this.select)
                .add(moment().utcOffset(), "minutes")
                .unix()
            : null
        });
        if (this.object.type === "moment__gte") {
          this.$store.commit("setPreQuery", {
            key: "moment__gte",
            value: this.select
              ? moment(this.select)
                  .startOf("day")
                  .unix()
              : null
          });
        }
        if (this.object.type === "moment__lte") {
          this.$store.commit("setPreQuery", {
            key: "moment__lte",
            value: this.select
              ? moment(this.select)
                  .endOf("day")
                  .unix()
              : null
          });
        }
        this.$store.dispatch("findResult");
      }
    },
    inputOnSelect(event) {
      if (typeof event === "object" && event && !!event.text && !!event.value) {
        this.select = event.text;
        this.$store.commit("setPreQuery", {
          key: "ids",
          value: event.value
        });
        this.$store.commit("setPreQuery", {
          key: "search",
          value: undefined
        });
      } else {
        this.select = undefined;
        this.$store.commit("setPreQuery", {
          key: "ids",
          value: undefined
        });
      }
    },
    debounceInputOnSelect: debounce(function(event) {
      this.inputOnSelect(event);
    }, 500),
    asyncSearch(value) {
      if (this.isLoading) return;

      this.isLoading = true;

      const allQuery = { ...this.queries.query, ...this.preQuery.query };

      this.object
        .function(value, allQuery)
        .then(res => (this.items = res))
        .catch(error => {
          console.error(error); // Handle the error properly
        })
        .finally(() => (this.isLoading = false));
    },
    sorting(arr) {
      if (Array.isArray(arr)) {
        return arr.sort();
      }
      return;
    }
  }
};
</script>
<style scoped>
.loading-block {
  pointer-events: none;
  opacity: 0.7;
}
</style>
