<template>
  <div class="filter">
    <div class="filter__inner">
      <div class="filter__title-wrap">
        <slot name="keep__text">
          <div>{{ keep }}</div>
        </slot>
        <h2
          v-if="activeRoute !== 'Инфопанель'"
          class="filter__title filter__title_grid-column_span-4"
        >
          <!--          <slot name="filter-title">-->
          <!--&lt;!&ndash;            <div v-if="titleCount">{{ activeRoute }}</div>&ndash;&gt;-->
          <!--            <div-->
          <!--              v-if="-->
          <!--                this.$route.name != 'ChildrenInfo' &&-->
          <!--                  this.$route.name != 'FromKindergartenChildren' &&-->
          <!--                  this.$route.name !=-->
          <!--                    'FromKindergartenFromChildrenGroupChildrenInfo'              "-->
          <!--            >-->
          <!--              Всего: {{ titleCount ? titleCount : 0 }}-->
          <!--            </div>-->

          <!--            -->
          <!--          </slot>-->
          <slot name="filter-title">
            <div v-if="titleCount">{{ activeRoute }}</div>
            <div>Всего: {{ titleCount || "0" }}</div>
          </slot>
        </h2>

        <slot name="download-button">
          <button
            v-if="downloadFunction"
            @click="downloadFunction"
            class="btn btn_color_grey btn-unload flex-right"
          >
            {{ downloadText }}
          </button>
        </slot>

        <slot name="add-button">
          <a
            v-if="addingFunction"
            @click="addingFunction"
            class="btn btn-add btn_color_green"
          >
            {{ addingText }}
          </a>
        </slot>

        <slot name="delete-button">
          <a
            v-if="disabled"
            @click="addingFunction"
            class="btn btn-delete btn_color_red"
          >
            {{ addingText }}
          </a>
        </slot>
      </div>
      <div class="filter__form">
        <SearchSelectField
          v-for="(query, index) in filteredQuery"
          :key="index"
          :object="query"
          :disabled="loading"
        ></SearchSelectField>
        <!--        <button-->
        <!--          v-if="queries && queries.length > 0"-->
        <!--          @click="findResult"-->
        <!--          class="btn btn_color_white btn_results"-->
        <!--        >-->
        <!--          Показать результаты-->
        <!--        </button>-->
      </div>
    </div>
  </div>
</template>

<script>
import SearchSelectField from "@/components/fields/SearchSelectField";
import tabbarLinks from "@/helpers/tabbars";

export default {
  name: "TableFilters",
  components: { SearchSelectField },
  props: {
    downloadText: {
      type: String,
      default: "Выгрузить"
    },
    keep: {
      type: String
    },
    titleCount: {
      type: Number
    },
    queries: {
      type: Array
    },
    addingText: {
      type: String,
      default: "Добавить"
    },
    downloadFunction: {
      type: Function,
      default: null
    },
    addingFunction: {
      type: Function,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeRoute() {
      const link = tabbarLinks.find(el => el.to.name === this.$route.name);
      return link ? link.text : "";
    },
    filteredQuery() {
      return this.queries?.filter(
        el =>
          el?.userRole?.length === 0 ||
          el?.userRole?.includes(this.$store.getters.getCurrentRole)
      );
    }
  },
  methods: {
    findResult() {
      this.$store.dispatch("findResult");
    }
  },
  beforeDestroy() {
    if (this.$route.path !== this.$route.fullPath)
      this.$router.replace({ query: null });
  }
};
</script>

<style scoped></style>
