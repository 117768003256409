<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to-raw="objects"
      routeObj="objects"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
      :restore-button="isRestore"
    >
      <template v-slot:restore-td-father="{ itemRestore }">
        <button @click="clickRestore(itemRestore)" class="btn-restore btn">
          Восстановить
        </button>
      </template>
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import moment from "moment";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "UsersObject",
  data() {
    return {
      loadingBtn: false,
      queries: query.objects.filter(el => el.type !== "diller"),
      headerPre: [
        {
          text: "Объект",
          value: "name",
          renderFunction: value => value.object_name,
          sort: true
        },
        {
          text: "Номер л/с",
          value: "accounts",
          sort: true,
          renderFunction: value => value.account
        },
        {
          text: "Карта",
          value: "cards",
          userRole: [60, 70],
          renderFunction: value => value.card_code,
          sort: true
        },
        {
          text: "Статус оплаты",
          renderFunction: value => {
            return value.user_payment_status ? "Оплачено" : "Не оплачено";
          }
        },
        {
          text: "Дата оплаты",
          renderFunction: value => {
            return value.user_payment_date_start &&
              moment(value.user_payment_date_start).isValid()
              ? moment(value.user_payment_date_start * 1000).format(
                  "DD.MM.YYYY"
                )
              : "-";
          }
        },

        {
          text: "Компания",
          value: "organization_name",
          userRole: [60, 70]
        },
        {
          text: "Тариф",
          value: "tariff_name"
        },
        {
          text: "Выбранная группа пользователей",
          value: "group_name"
        },
        {
          text: "Доступные группы пользователей",
          renderFunction: value => {
            return value.groups.reduce((firstValue, secondValue) => {
              firstValue += secondValue + "</br>";

              return firstValue;
            }, "");
          }
        },
        {
          text: "Сумма",
          renderFunction: value =>
            `${value.paid_amount ? value.paid_amount + "₽" : "" || "-"}`
        }
      ],
      isRestore: false
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return this.headerPre.filter(
        el =>
          !el.userRole ||
          (!!el.userRole &&
            el.userRole.includes(this.$store.getters.getCurrentRole))
      );
    },

    sourceObject() {
      return this.$store.getters.getUserObjectsList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  mounted() {
    if (this.urlQuery.query?.is_deleted == "true") {
      this.isRestore = true;
    } else {
      this.isRestore = false;
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    const queryParam = {
      ...this.urlQuery.query,
      groups__user: this.urlQuery.id
    };

    if (this.$store.getters.getCurrentRole === 30) {
      queryParam["exclude-type"] = "kindergarten";
    }

    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: queryParam
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchObjectsListUser").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        if (to.query?.is_deleted == "true") {
          this.isRestore = true;
        } else {
          this.isRestore = false;
        }
        const queryParam = {
          ...this.urlQuery.query,
          groups__user: this.urlQuery.id
        };

        if (this.$store.getters.getCurrentRole === 30) {
          queryParam["exclude-type"] = "kindergarten";
        }

        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: queryParam
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchObjectsListUser").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadUserObjectsList", this.header).then(() => {
        this.loadingBtn = false;
      });
    },
    clickRestore(item) {
      this.isDateLoaded = true;
      this.$store
        .dispatch("restoreObject", { id: item.id, data: item })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.restore(successMessageDictionary.object)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchObjectsListUser");
        })
        .finally(() => {
          this.isDateLoaded = false;
        });
    }
  }
};
</script>

<style scoped></style>
